import http from "@/axios";
import systemIp from "@/systemIp/systemIp";
let apiDataBoard = systemIp.apiDataBoard;

//获取账号列表
export const getAdminUserIndex = (params) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/admin/user/index`,
        method: "GET",
        traditional: true,
        params,
      })
      .then((res) => {
        resolve(res);
      });
  });
};

//获取账号下拉列表
export const getAdminUserSelect = (params) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/admin/user/select`,
        method: "GET",
        traditional: true,
        params,
      })
      .then((res) => {
        resolve(res);
      });
  });
};
//获取部门下拉列表
export const getDepartmentSelect = (params) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/department/select`,
        method: "GET",
        traditional: true,
        params,
      })
      .then((res) => {
        resolve(res);
      });
  });
};
//账号详情
export const getAdminUserShow = (params) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/admin/user/show`,
        method: "GET",
        traditional: true,
        params,
      })
      .then((res) => {
        resolve(res);
      });
  });
};

//新增账号
export const adminUserAdd = (data) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/admin/user/add`,
        method: "POST",
        data,
      })
      .then((res) => {
        resolve(res);
      });
  });
};
//编辑账号
export const adminUserUpdate = (data) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/admin/user/update`,
        method: "POST",
        data,
      })
      .then((res) => {
        resolve(res);
      });
  });
};

//删除账号
export const adminUserDelete = (data) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/admin/user/delete`,
        method: "POST",
        data,
      })
      .then((res) => {
        resolve(res);
      });
  });
};

//查看手机号
export const getShowPhone = (data) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/phone_show`,
        method: "POST",
        data,
      })
      .then((res) => {
        resolve(res);
      });
  });
};

//账号解冻
export const thawUpdate = (data) => {
  return new Promise((resolve) => {
    http
      .request({
        url: `${apiDataBoard}/admin/user/unfreeze`,
        method: "POST",
        data,
      })
      .then((res) => {
        resolve(res);
      });
  });
};