import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "loginIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },

  {
    path: "/loginhz",
    name: "loginIndexHz",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login-hz.vue"),
  },

  {
    path: "/",
    // path: "/loginview",
    name: "loginView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/loginView.vue"),
  },

  {
    path: "/home",
    name: "HomeView",
    component: () => import("../views/HomeView.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
