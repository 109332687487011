<template>
  <span class="fomartPhone">
    <span
      class="phont_p phont_cur"
      v-show="myResult == '' && phone != ''"
      @click="showPhone"
    >
      <span style="color: #2c6af0">查看手机号</span>&nbsp;
      <i class="el-icon-view"></i>
    </span>
    <span v-show="phone == ''"> -- </span>
    <span class="phont_p" v-show="myResult != ''">
      <span>{{ myResult }}</span>
    </span>
  </span>
</template>

<script>
import { getShowPhone } from "@/api/accountManagement";

export default {
  name: "fomartPhoneIndex",

  data() {
    return {
      myResult: "", //当前评论
      myPhone: this.phone,
    };
  },
  props: ["phone"],
  mounted() {},
  watch: {

    phone: {
      handler: function () {
        this.myResult = "";
      },
      immediate: true,
    },
  },
  methods: {
    showPhone() {
      this.$confirm("确定查询此用户手机号吗？")
        .then(() => {
          this.getUserPhone();
        })
        .catch(() => {});
    },
    getUserPhone() {
      const formData = new FormData();
      formData.append("phone", this.phone); //藏品id
      getShowPhone(formData).then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data.dictionary_info.options);
          let ar = res.data.data;
          this.myResult = ar;
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fomartPhone {
  .phont_cur {
    cursor: pointer;
  }
}
</style>
