<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {},
};

// 解决ERROR ResizeObserver loop completed with undelivered notifications.
//问题的
// const debounce = (fn, delay) => {
//   let timer = null;
//   return function () {
//     let context = this;
//     let args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   };
// };
// // 解决ERROR ResizeObserver loop completed with undelivered notifications.
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(callback) {
//     callback = debounce(callback, 1);
//     super(callback);
//   }
// };

</script>

<style lang="scss">
@import "@/assets/css/animate.css";
@import "@/assets/css/elementUi_public.scss";
#app {
  width: 100vw;
  height: 100vh;
}
</style>
