import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 富文本
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// //图片预览插件
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
// //全局使用
// Vue.use(Viewer)
// //设置初始值
// Viewer.setDefaults({
//   Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
// })

import fomartPhone from "./components/a-components/fomartPhone.vue";
Vue.component("fomartPhone", fomartPhone);

import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式

Vue.use(UmyUi);

Vue.use(VueQuillEditor);

Vue.use(Element);
Vue.config.productionTip = false;

// let meta = document.createElement('meta')
// meta.name = 'viewport'
// meta.content = "width = device - width, initial - scale=1.0"
// document.head.appendChild(meta)
const bus = new Vue();
// 将事件总线挂载到Vue的原型链上，这样在任何组件中都可以通过this.$bus来访问
Vue.prototype.$bus = bus;
// app.vue写在script里面  main.js写在app挂载完之后
const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
